import { useState } from "react";
import { Col, Container, Row, Button, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import OAcIcon from "../../resources/OAc_icon_2.png";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const OAcSmart = (props) => {
    return (
        <div className="min-vh-100">
            <div className="">
                <Container className="py-5">
                    <OldPageAlert />
                    <h1 className="mt-5">OAc Smart</h1>
                    <h3 className="mt-3">OAc Smartはスマートフォンを用いてログインする方法です．</h3>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-phone"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>over.nnct18j.com</h2>
                            <h4>にアクセス！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                スマートフォンでPCと同じようにログインし，<Button variant="success"><img src={OAcIcon} width="26" height="26" className="me-2" />OAcログイン</Button>を押して下さい．
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-upc-scan"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>二次元コード</h2>
                            <h4>をスキャン！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                利用したいゲームやサービスで，表示される二次元コードをスキャン！<br />簡単に，安全にログインできます．
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-shield-lock"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>安全</h2>
                            <h4>なのはどうして？</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                二次元コードには有効期限が短く設定されたセッションIDが格納されています．<br />
                                しかし，このセッションIDが仮に第三者の手に渡っても，使用する際は送信元のクライアントを厳格に検証するため，エンドユーザが使用することはできません．<br />
                                ですので，仮にOVERを名乗るニセモノのブースが近くで運用され，あなたが二次元コードを誤って提供してしまったとしても，あなたの大切なハイスコアが削除されることはありません(だからといってむやみに二次元コードを見せびらかしてはいけませんよ！)．
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-question-circle"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>うまくいきませんか？</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                下記をお試し下さい．
                                <ul className="mt-2">
                                    <li>二次元コードの全体が映されているかを確認する</li>
                                    <li>画面の明るさを最大にする</li>
                                    <li><i class="bi bi-arrow-clockwise"></i>を押してやり直してみる</li>
                                    <li>お近くの担当学生に問い合わせてみてください</li>
                                </ul>
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-laptop"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>PC</h2>
                            <h4>しかないんだけど...</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                安心して下さい．開発熱心な開発系高専生のことも想定されています．<br />{props.allowNotPhone === undefined ? <span>ログイン画面を開いてみて下さい</span> : <span>
                                    <Link to="#" onClick={props.allowNotPhone}>ここをクリック</Link>して，ログインを開始して下さい．
                                </span>}
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-credit-card"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OAc Card ログイン</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                NFCに対応したお手持ちのカードを用いてログインする方法です．合わせてご確認ください．
                            </h5>
                            <p>NFCはUSA及びその他の国における、NFC Forum, Inc.の商標または登録商標です。</p>
                            <Button as={Link} to="/oac/card" className="mt-4">詳細</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default OAcSmart;