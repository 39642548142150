import { useState } from "react";
import { Container, Nav, Navbar, Button, Modal, Row, Col, Spinner } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Icon from "../../resources/over_icon.png";
import Logo from "../../resources/over_logo.png";
import OAcIcon from "../../resources/OAc_icon_2.png";
import {isMobile} from "react-device-detect";

const Header = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { user, account } = props.auth;
    const [expanded, setExpanded] = useState(false);
    return (
        <header>
            <Navbar fixed="top" expand="lg" bg="dark" variant="dark" expanded={expanded || !isMobile}>
                <Container>
                    <Navbar.Brand onClick={() => setExpanded(false)} to="/" as={Link}><img src={Icon} width="30" height="30" /><img src={Logo} style={{ marginLeft: "10px" }} height="30" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            {/* <Nav.Link onClick={() => setExpanded(!expanded)} as={Link} to="a" aria-label="Toggle navigation" aria-controls="basic-navbar-nav">ハイスコア</Nav.Link> */}
                            <Nav.Link onClick={() => setExpanded(!expanded)} as={Link} to="/game/laserhythm">Laserhythm</Nav.Link>
                            <Nav.Link onClick={() => setExpanded(!expanded)} as={Link} to="/game/blindism">BlinDISM</Nav.Link>
                            {/* {user === null && account === null ? <Button onClick={() => setExpanded(!expanded)} as={Link} to="#" className="ms-lg-4" variant="primary" onClick={() => { handleShow() }}>ログイン</Button>
                                : account !== null && user === null  ? <Button as={Link} to="#" className="ms-lg-4" variant="secondary" >
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /><span className="ms-2">ログイン</span>
                                </Button> : <Button onClick={() => setExpanded(!expanded)} as={Link} to="/oac" className="ms-lg-4" variant="success"><img src={OAcIcon} width="26" height="26" className="me-2" />OAcログイン</Button>} */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* <Modal show={show} onHide={handleClose} centered bg="dark">
                <Modal.Header className="bg-dark text-light">
                    <Modal.Title>ログイン</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-light">
                    <Container>
                        長野高専の教員，または学生のログインが可能です．<br /><span className="text-danger">一般の方はご利用いただけません．</span><a href="https://koreisai.tech" target="_blank" rel="noopener noreferrer">詳細はこちら</a>．
                        <Row className="mt-4">
                            <Col sm={12} md={6} className="text-center">
                                <Button size="lg" onClick={() => { props.authFunc("nagano-nct.ac.jp"); handleClose() }}>教員ログイン</Button>
                            </Col>
                            <Col sm={12} md={6} className="mt-2 mt-md-0 text-center">
                                <Button size="lg" variant="success" onClick={() => { props.authFunc("g.nagano-nct.ac.jp"); handleClose() }}>学生ログイン</Button>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer className="bg-dark text-light">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </header>
    );
}

export default withRouter(Header);
