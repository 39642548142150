const firebaseConfig = {
    apiKey: "AIzaSyAxKOKyukLbYvqhUbfzgPEQszlHTw5MAHc",
    authDomain: "over4sj-2.firebaseapp.com",
    projectId: "over4sj-2",
    storageBucket: "over4sj-2.appspot.com",
    messagingSenderId: "222675231098",
    appId: "1:222675231098:web:60645891e1daa07efa36c3",
    measurementId: "G-SBGV4Y6W7V"
};

export { firebaseConfig };