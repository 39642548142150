import { useState } from "react";
import { Col, Container, Row, Button, Navbar, Nav } from "react-bootstrap";
import nnct from "../../resources/nnct.png";
import Icon from "../../resources/over_icon.png";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const NNCTStamp = (props) => {
    return (
        <div className="min-vh-100">
            <div className="">
                <Container className="py-5">
                    <OldPageAlert />
                    <h1 className="mt-5">スタンプラリー連携</h1>
                    <h3 className="mt-3" style={{ lineHeight: "30px" }}>
                        OVERのゲームをプレイするだけで，自動でスタンプが押されるようになります．</h3>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-person-circle"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OVERアカウント</h2>
                            <h4>を作成！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                {props.user !== null ? <span><i class="bi bi-check me-2 text-success " style={{ fontSize: "32px" }}></i>すばらしい！あなたはすでに{props.user.displayId}としてログイン済みです</span> : "画面上部のログインより，高専のアカウントでログイン！"}
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center><img src={nnct} className="" height="200px" /></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>スタンプラリーアカウント</h2>
                            <h4>を作成！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                OVERのゲームで遊ぶ<b>前に</b>， <a href="https://koreisai.tech" target="_blank" rel="noopener noreferrer">工嶺祭公式ページ</a>よりアカウントを作成して下さい．
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center><img src={Icon} className="" width="200px" /></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OVERのゲーム</h2>
                            <h4>で遊ぶ！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                準備は完了！OVERのゲームで遊ぶと，自動的にスタンプが押されます(OAc Smart/Card 両者対応しています)
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-question-circle"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>うまくいきませんか？</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                もし正常にスタンプが押されない場合，通常のように<a href="https://koreisai.tech" target="_blank" rel="noopener noreferrer">工嶺祭公式ページ</a>より二次元コードを読み込んでスタンプを押す方法も使用できます．
                            </h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default NNCTStamp;