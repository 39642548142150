import { Carousel, CarouselItem, Col, Container, Row } from "react-bootstrap";
import laserhthm from "../../resources/laserythm.png";
//import jumpay from "../../resources/JumPay-trans.png";
import oac from "../../resources/OAc_icon.png";
import useWindowDimensions from "../../functions/useWindowDementions";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import danceImage from "../../resources/laserythm/dance.jpg";
import back from "../../resources/laserythm/back.jpg";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const images = [back];

const Laserhythm = (props) => {
    const { height, width } = useWindowDimensions();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 500);
    }, []);
    return (
        <div>
            <Carousel style={{ height }}>
                {images.map(item => <Carousel.Item height={height}>
                    <div className="text-center" style={{ height, overflow: "hidden" }}>
                        <CSSTransition
                            in={ready}
                            timeout={700}
                            classNames="logo-animation"
                            onExited={() => setReady(false)}
                            unmountOnExit
                        >
                            <img src={item} className="d-block" style={{
                                width: "auto", minHeight: height, minWidth: width, marginLeft: "50%",
                                transform: "translateX(-50%)"
                            }} />
                        </CSSTransition>
                    </div>
                    {/* <Carousel.Caption>
                            <h3>山の画像</h3>
                            <p>ぎゃはは</p>
                        </Carousel.Caption> */}
                </Carousel.Item>)}
            </Carousel>
            <Container className="py-5">

                <div className="position-absolute top-50 start-50 translate-middle">
                    <CSSTransition
                        in={ready}
                        timeout={700}
                        classNames="logo-animation"
                        onExited={() => setReady(false)}
                        unmountOnExit
                    >
                        <img src={laserhthm} width={width > 1200 ? "auto" : width * 0.6} style={{ maxHeight: "250px" }} />
                    </CSSTransition>
                </div>
                <OldPageAlert />
                <h2 >次世代の非接触型音ゲーム</h2>
                <h4 className="mt-5" style={{ lineHeight: "30px" }}>
                    ボタンがなく，一切モノに触れない音ゲー，誕生！
                </h4>
                <h1 className="text-center mt-5">GAME</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={danceImage} width="80%" className="rounded-pill" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>空を切り，奏でられる音色</h2>
                        <h4>次世代の演奏</h4>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src="https://junkee.com/wp-content/uploads/2019/10/Blank-1024-x-683-40.jpg" width="80%" className="rounded-pill" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>躍動感</h2>
                        <h4>気分はダンサー！</h4>
                    </Col>
                </Row>

                <h1 className="text-center mt-5">FEATURES</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center style={{ fontSize: "200px" }}><i class="bi bi-keyboard"></i></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>レーザー</h2>
                        <h4>入力方法</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            本ゲームでは，独自開発のレーザーユーティリティを用いて操作します．<br />
                            実際にモノと接触しないため，新時代に対応した仕様となっております．
                        </h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={oac} className="ml-auto" width="80%" style={{ "line-height": "3px" }} /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>OAc</h2>
                        <h4>ログイン方法</h4>
                        <h5 className="mt-4">OAc Smart/Card 両者対応</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Laserhythm;
