import { Carousel, CarouselItem, Col, Container, Row, Button } from "react-bootstrap";
import laserhthm from "../../resources/laserythm.png";
import blindism from "../../resources/blindism.png"
//import jumpay from "../../resources/JumPay-trans.png";
import jumpay from "../../resources/JumPay_white.png";
import oac from "../../resources/OAc_icon.png";
import Logo from "../../resources/over_logo.png";
import Icon from "../../resources/over_icon.png";
import useWindowDimensions from "../../functions/useWindowDementions";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";
import overBack from "../../resources/over-back.jpg";
import { Link } from "react-router-dom";
import nnct from "../../resources/nnct.png";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const images = [overBack, "https://wallpaperaccess.com/full/368244.jpg", "https://wallpaperaccess.com/download/awesome-space-2610778"];

const Top = (props) => {
    const { height, width } = useWindowDimensions();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 500);
    }, []);
    return (
        <div>
            <Carousel style={{ height }}>
                {images.map(item => <Carousel.Item height={height}>
                    <div className="text-center" style={{ height, overflow: "hidden" }}>
                        <CSSTransition
                            in={ready}
                            timeout={700}
                            classNames="logo-animation"
                            onExited={() => setReady(false)}
                            unmountOnExit
                        >
                            <img src={item} className="d-block" style={{
                                width: "auto", minHeight: height, minWidth: width, marginLeft: "50%",
                                transform: "translateX(-50%)"
                            }} />
                        </CSSTransition>
                    </div>
                    {/* <Carousel.Caption>
                            <h3>山の画像</h3>
                            <p>ぎゃはは</p>
                        </Carousel.Caption> */}
                </Carousel.Item>)}
            </Carousel>
            <Container className="pb-5">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <CSSTransition
                        in={ready}
                        timeout={700}
                        classNames="logo-animation"
                        onExited={() => setReady(false)}
                        unmountOnExit
                        clsssName="w-100"
                    >
                        <div><img src={Icon} width={width > 900 ? "auto" : width * 0.1} style={{ marginRight: "10px", maxHeight: "80px" }} /><img src={Logo} width={width > 900 ? "auto" : width * 0.35} style={{ maxHeight: "100px" }} /></div>
                    </CSSTransition>
                </div>
                <OldPageAlert />
                <h2 className="mt-5 text-center">OVER 〜そして物語は加速する〜</h2>
                <h4 className="text-center mt-4">長野高専第56回工嶺祭 4SJ合同クラス企画のOVERゲームセンターです．</h4>
                <h1 className="text-center mt-5">GAMES</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={laserhthm} className="" width="80%" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>Laserhythm</h2>
                        <h4>次世代の非接触型音ゲーム</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            ボタンがなく，一切モノに触れない音ゲー，誕生！
                        </h5>
                        <Button as={Link} to="/game/laserhythm" className="mt-4">詳細</Button>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={blindism} className="ml-auto" width="80%" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>BlinDISM</h2>
                        <h4>生を望み、死に向かう</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>これは、進む少女のお話<br /></h5>
                        <Button as={Link} to="/game/blindism" className="mt-4">詳細</Button>
                    </Col>
                </Row>
                <h1 className="text-center mt-5">FEATURES</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={oac} className="" width="80%" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>OAc</h2>
                        <h4>OVERゲームセンター ログインシステム</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>早く，賢く，安全に．<br />
                            OAcは，あなたのスマートフォンを用いた賢いログインメソッドです．<br />
                            画面に表示される二次元コードを，ゲーム筐体に提示するだけ！<br />
                            また，あなたが持ち合わせているカードやOVERオリジナルカードを用いたCardログインも提供しています．<br />
                            詳しくは来場時に担当学生にお問い合わせ下さい</h5>
                        <Button as={Link} to="/oac/smart" className="mt-4">OAc Smartについて</Button><br />
                        <Button as={Link} to="/oac/card" className="mt-2">OAc Cardについて</Button>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={jumpay} className="ml-auto" width="80%" style={{ "line-height": "3px" }} /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>JumPay</h2>
                        <h4>OVERゲームセンター 決済システム</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>ゲームをプレイするとJumPay Pointがたまります<br />ポイントでおかしやオリジナルグッズを手に入れましょう．</h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={nnct} className="ml-auto" width="200px" style={{ "line-height": "3px" }} /></center>

                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>スタンプラリー連携</h2>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            工嶺祭実行係の方々のご協力により，スタンプラリーとの連携ができるようになりました！<br />
                            OVERのゲームをプレイするだけで，自動でスタンプが押されるようになります．</h5>
                        <Button as={Link} to="/stamp" className="mt-4">詳細</Button><br />
                    </Col>
                </Row>
                <h1 className="text-center mt-5">ACCESS</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center style={{ fontSize: "200px" }}><i class="bi bi-geo-alt"></i></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>S科棟2階の実験室</h2>
                        <h4>でお待ちしております！</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            場所がわからなかったらお近くのS科生にお問い合わせください．
                        </h5>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Top;
