import { Alert } from "react-bootstrap";

const OldPageAlert = () => {
    return (
        <Alert variant="dark" style={{marginTop: "10px", marginBottom: "10px"}}>
              このページは2021年度に実施された 第56回 長野高専工嶺祭 4SJ合同クラス企画のページです．最新情報は<a href="https://over.nnct18j.com" target="_blank" rel="noreferrer">こちら</a>．
        </Alert>
    );
};

export default OldPageAlert;