import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "../../resources/over_icon.png";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const OAcNFC = (props) => {
    return (
        <div className="min-vh-100">
            <div className="">
                <p> a</p>
                <Container className="py-5">
                    <OldPageAlert />
                    <h1 className="mt-5">OAc Card</h1>
                    <h3 className="mt-3">OAc CardはNFCに対応するカードを用いてログインする方法です．</h3>
                    <p>NFCはUSA及びその他の国における、NFC Forum, Inc.の商標または登録商標です。</p>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-credit-card"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>NFC対応カード</h2>
                            <h4>でログイン！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                交通系やあのゲームセンターのカード，またOVERオリジナルカードが使えます！<br />対応カードはOVERゲームセンターに起こしいただく際に，お近くの担当学生にお問い合わせください．
                            </h5>
                        </Col>
                    </Row>
                    <center className="mt-5"><h2>HOW TO</h2></center>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-person-circle"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OVERアカウント</h2>
                            <h4>を作成！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                {props.user !== null ? <span><i class="bi bi-check me-2 text-success " style={{ fontSize: "32px" }}></i>すばらしい！あなたはすでに{props.user.displayId}としてログイン済みです</span> : "画面上部のログインより，高専のアカウントでログイン！"}
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><img src={Icon} width="200px" /></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OVERゲームセンター</h2>
                            <h4>へGO！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                サポートデスクにて「Cardログインを設定したい」とお声がけ下さい
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-box-arrow-in-right"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OAc Smart / OAc Card</h2>
                            <h4>でログイン！</h4>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                設定にはスマートフォン，またはすでに設定したカードが必要です．
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-credit-card"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>設定完了！</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                これで次回からのログインは，登録したカードを使うことができます！
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-question-circle"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>お困りですか？</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                <ul className="mt-2">
                                    <li>カードをなくした！</li>
                                    <ul>
                                        <li><Link>マイページ</Link>より，カードログインを無効化することができます</li>
                                    </ul>
                                    <li>交通系の残高は引かれるの？</li>
                                    <ul>
                                        <li><b>残高を操作することは一切ございません．</b>しかし，安全のため，使用するカードは「残高のシステムがないゲーセンのカード」もしくは「残高が入っていないカード」を使うことを推奨します</li>
                                        <li>OAc Cardログインを使用したことによるいかなる不具合に対して，我々は責任を追わないものとします</li>
                                    </ul>
                                    <li><i class="bi bi-arrow-clockwise"></i>を押してやり直してみる</li>
                                    <li>お近くの担当学生に問い合わせてみてください</li>
                                </ul>
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12} md={6} className="">
                            <center style={{ fontSize: "200px" }}><i class="bi bi-phone"></i></center>
                        </Col>
                        <Col sm={12} md={6} className="mt-4 mt-md-0">
                            <h2>OAc Smart ログイン</h2>
                            <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                                お手持ちのスマートフォンを用いたログイン方法です．合わせてご確認下さい．
                            </h5>
                            <Button as={Link} to="/oac/smart" className="mt-4">詳細</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default OAcNFC;