import { Carousel, CarouselItem, Col, Container, Row } from "react-bootstrap";
import blindism from "../../resources/blindism.png"
//import jumpay from "../../resources/JumPay-trans.png";
import oac from "../../resources/OAc_icon.png";
import useWindowDimensions from "../../functions/useWindowDementions";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import back from "../../resources/blindism_back.png";
import { Link } from "react-router-dom";
import back1 from "../../resources/blindism/blindism_back1.png";
import back2 from "../../resources/blindism/blindism_back2.png";
import report from "../../resources/blindism/report.jpg";
import OldPageAlert from "../OldPageAlert/OldPageAlert";

const images = [back];

const BlinDISM = (props) => {
    const { height, width } = useWindowDimensions();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 500);
    }, []);
    return (
        <div >
            <Carousel style={{ height }}>
                {images.map(item => <Carousel.Item height={height}>
                    <div className="text-center" style={{ height, overflow: "hidden" }}>
                        <CSSTransition
                            in={ready}
                            timeout={700}
                            classNames="logo-animation"
                            onExited={() => setReady(false)}
                            unmountOnExit
                        >
                            <img src={item} className="d-block" style={{
                                width: "auto", minHeight: height, minWidth: width, marginLeft: "50%",
                                transform: "translateX(-50%)"
                            }} />
                        </CSSTransition>
                    </div>
                    {/* <Carousel.Caption>
                            <h3>山の画像</h3>
                            <p>ぎゃはは</p>
                        </Carousel.Caption> */}
                </Carousel.Item>)}
            </Carousel>
            <Container className="py-5">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <CSSTransition
                        in={ready}
                        timeout={700}
                        classNames="logo-animation"
                        onExited={() => setReady(false)}
                        unmountOnExit
                    >
                        <img src={blindism} width={width > 500 ? "auto" : width * 0.6} style={{ maxHeight: "80px" }} />
                    </CSSTransition>
                </div>
                <OldPageAlert />
                <h2 className="">生を望み、死に向かう</h2>
                <h4 className="mt-5" style={{ lineHeight: "30px" }}>これは、進む少女のお話</h4>
                <h1 className="text-center mt-5">GAME</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={back1} width="80%" className="rounded-pill" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>これは、進む少女のお話</h2>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            NNCT18SJ合同企画「『Over』～そして物語は加速する～」出展作品です。</h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={back2} width="80%" className="rounded-pill" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>記憶</h2>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            少女はとある空間で目覚め、自由を求めて進み続けます。閉鎖された世界を抜けた先を、あなたに確かめて頂きたい。
                        </h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                    <center style={{ fontSize: "200px" }}><i class="bi bi-twitter"></i></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2><a href="https://twitter.com/hashtag/ProjectBLinD?src=hashtag_click" target="_blank" rel="noopener noreferrer">#ProjectBLinD</a></h2>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            ゲームに対する感想，スコアのシェア，考察などはこちらのハッシュタグへ．
                        </h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={report} width="80%" className="rounded-pill" /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>絶望</h2>
                        <h4>遥かなる敵: 手書きレポート</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            許してはいけない</h5>
                    </Col>
                </Row>
                <h1 className="text-center mt-5">FEATURES</h1>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center style={{ fontSize: "200px" }}><i class="bi bi-keyboard"></i></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>キーボード</h2>
                        <h4>入力方法</h4>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>
                            本作ではPCキーボードを用いて操作します<br />
                            WASDを用いた簡単なキー配列により，ゲームが好きなカジュアルゲーマーからPCゲームになれたプロゲーマーの幅広い皆様にお楽しみいただけます．</h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center><img src={oac} className="ml-auto" width="80%" style={{ "line-height": "3px" }} /></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>OAc</h2>
                        <h4>ログイン方法</h4>
                        <h5 className="mt-4">OAc Smart/Card 両者対応</h5>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={12} md={6} className="">
                        <center style={{ fontSize: "200px" }}><i class="bi bi-github"></i></center>
                    </Col>
                    <Col sm={12} md={6} className="mt-4 mt-md-0">
                        <h2>Download</h2>
                        <h5 className="mt-4" style={{ lineHeight: "30px" }}>製作者K_Whiteのご厚意により，当ゲームを皆様のPCでも遊べるように配布を行なっています．<br />
                            遊ぶ前にトレーニングしておきたい人，じっくり遊んでおきたい人，ストーリーをより深く考察したい人はぜひダウンロードして遊んで下さい．<br />
                            <svg role="img" className="mt-4" width={32} height={32} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="white"><title>Windows</title><path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801" /></svg>
                        </h5>
                        <a href="https://github.com/K-White0529/BLinDISM/releases/latest" target="_blank" rel="noopener noreferrer" style={{ fontSize: "32px" }} className="text-light mt-4"><i class="bi bi-download"></i></a>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BlinDISM;
