import './App.css';
import { Container, Nav, Navbar, Button, Modal, Row, Col, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import Header from './components/Header/Header';
import { BrowserRouter as Router, Route } from "react-router-dom";
import MyPage from './components/MyPage/MyPage';
import Top from './components/Top/Top';
import BlinDISM from './components/BlinDISM/BlinDISM';
import { useEffect, Fragment, useState } from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Laserhythm from './components/Laserhythm/Laserhythm';
import { getAuth, GoogleAuthProvider, signInWithPopup, getIdToken, signOut, onAuthStateChanged } from "firebase/auth";
import { app, isDevelop } from "./Firebase";
import axios from 'axios';
import Icon from "./resources/over_icon.png";
import OAc from './components/OAc/OAc';
import Credit from './components/Credit/Credit';
import OAcSmart from './components/OAcSmart/OAcSmart';
import OAcNFC from './components/OAcNFC/OAcNFC';
import NNCTStamp from './components/NNCTStamp/NNCTStamp';

const apiHost = "https://asia-northeast1-over4sj-2.cloudfunctions.net/api";

function App() {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);
  const [userChecking, setUserChecking] = useState(false);
  const [showAccountCreationModal, setShowAccountCreationModal] = useState(false)
  const handleClose = () => setShowAccountCreationModal(false);
  const handleShow = () => setShowAccountCreationModal(true);
  const [overDisplayName, setOverDisplayName] = useState("");
  const [detectError, setDetectError] = useState(false);

  const auth = (hd) => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      hd
    });

    const auth = getAuth(app);
    signInWithPopup(auth, provider).catch(e => {
      console.error(e);
      alert("認証エラー: " + e.toString());
    });
  }

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        console.log(user)
        setAccount(user);
        checkUser();
      } else {
        setAccount(null);
        setUser(null);
      }
    });
  }, [])
  console.log(account)

  const checkUser = async (justCheck = false) => {
    const result = await axios.get(`${apiHost}/accounts`, {
      headers: { authorization: `Bearer ${await getIdToken(getAuth().currentUser)}` },
      validateStatus: function (status) {
        return status >= 200;
      }
    });
    console.log(result.data)
    if (result.status !== 200) {
      if (result.data?.type !== "USER_NOT_CREATED" || justCheck) {
        setAccount(null);
        signOut(getAuth());
        if (justCheck) {
          alert("エラーが発生したため，ログアウトします．");
        }
        return;
      } else {
        handleShow();
      }
    } else {
      setUser(result.data);
      handleClose();
    }
    setUserChecking(false);
  }

  const createOverAccount = async () => {
    console.log(overDisplayName);
    const result = await axios.post(`${apiHost}/accounts`, {
      displayId: overDisplayName
    }, {
      headers: { authorization: `Bearer ${await getIdToken(getAuth().currentUser)}` },
      validateStatus: function (status) {
        return status >= 200;
      }
    });
    if (result.status !== 200) {
      console.error(result.data);
      alert("エラーが発生しました");
      setUserChecking(false);
      return;
    }
    setTimeout(() => { checkUser(true) }, 1000);
  }
  console.log(user);

  const checkNameValidate = (event) => {
    event.preventDefault();
    if (!userChecking && (/[0-9a-zA-Z\\-\\_]{3,10}/).test(overDisplayName)) {
      setUserChecking(true); createOverAccount();
    } else {
      setDetectError(true);
    }
    return false;
  }

  return (
    <div className="App bg-dark text-light" bg="dark" >
      <Router>
        <Fragment>
          <ScrollToTop />
          <Header authFunc={auth} auth={{ user, account }} accountCreationOpenFunc={handleShow} />
          <Route exact path="/" component={Top} />
          {/* <Route path="/my-page" component={MyPage} /> */}
          <Route path="/oac/smart" render={() => <OAcSmart user={user} />} />
          <Route path="/oac/card" render={() => <OAcNFC user={user} />} />
          <Route exact path="/oac" render={() => <OAc key={user === null ? "guest" : user.userId} user={user} />} />
          {/* <Route path="/credits" component={Credit} /> */}
          <Route path="/game/blindism" component={BlinDISM} />
          <Route path="/game/laserhythm" component={Laserhythm} />
          <Route path="/stamp" render={() => <NNCTStamp key={user === null ? "guest" : user.userId} user={user} />} />
        </Fragment>
      </Router>
      <Modal show={showAccountCreationModal} onHide={handleClose} centered bg="dark" backdrop="static">
        <Modal.Header className="bg-dark text-light">
          <Modal.Title><img src={Icon} width="24" height="24" className="me-2" />OVERアカウント作成</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <Container>
            {account?.displayName}さん，OVERへようこそ！<br />アカウントを作成されると，自動的に{account?.email}へ紐付けられます．
            <Form onSubmit={checkNameValidate}>
              <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                <FloatingLabel
                  controlId="floatingInput"
                  label="OVER ID"
                  className="mb-3 bg-dark"
                >
                  <Form.Control className="bg-dark text-light" type="text" placeholder="over_account" disabled={userChecking} value={overDisplayName} onChange={(e) => setOverDisplayName(e.target.value)} />
                </FloatingLabel>
               {detectError && <p className="text-danger">OVER IDは3〜10文字の英数字，またはアンダースコア(_)である必要があります</p>}
              </Form.Group>
            </Form>
          </Container>

        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="secondary" onClick={() => { if (!userChecking) { handleClose(); signOut(getAuth()) } }}>
            ログアウト
          </Button>
          <Button variant="primary" onClick={checkNameValidate}>
            {userChecking && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}<span className={userChecking ? "ms-2" : ""}>アカウント作成</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
