import { useEffect, useState } from "react";
import { Col, Container, Row, Button, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import OAcSmart from "../OAcSmart/OAcSmart";
import LoginHiSound from "../../resources/Login_Hi2.mp3";
import axios from "axios";
import { sessionApi } from "../../Firebase";
import { getAuth, getIdToken } from "@firebase/auth";
import QRcode from "qrcode.react";
import useWindowDimensions from "../../functions/useWindowDementions";
import { isMobile } from "react-device-detect";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import Icon from "../../resources/over_icon.png";

const ready =  window.location.hostname !== "over.nnct18j.com";

const OAc = (props) => {
    const expireIn = 300;
    const { height, width } = useWindowDimensions();
    const [disablePCCheck, setDisablePCCheck] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [expireTimer, setExpireTimer] = useState(-1);
    const [dbUnsubscribe, setDbUnsubscribe] = useState(null);
    const [consoleName, setConsoleName] = useState("");
    const [showLoggedIn, setShowLoggedIn] = useState(false);
    const getExpireTimer = () => (expireTimer);
    const audio = new Audio(LoginHiSound);
    const db = getFirestore();
    const startSession = async () => {
        setSessionId(null);
        if (dbUnsubscribe !== null) {
            dbUnsubscribe();
            setDbUnsubscribe(null);
        }
        let result;
        try{
            result = await axios.post(`${sessionApi}/session`, {}, {
                headers: { authorization: `Bearer ${await getIdToken(getAuth().currentUser)}` },
                validateStatus: function (status) {
                    return status >= 200;
                }
            });
        }catch(e){
            console.log(e);
            alert("セッションIDが取得できませんでした．サイトを読み込み直して下さい．");
        }
        if (result.data.status === "error" || result.status !== 200) {
            console.error("Can't fetch session id");
            console.error(result.data);
            alert("セッションIDが取得できませんでした．サイトを読み込み直して下さい．")
        } else {
            setSessionId(result.data.data.session_id);
            console.log(result.data.data.session_id)
            setExpireTimer(expireIn);
            const unsub = onSnapshot(doc(db, "sessions", result.data.data.session_id), (doc) => {
                console.log(doc.data());
                if (doc.data().console_status !== undefined) {
                    setConsoleName(doc.data().console_status.client_id);
                    startSession();
                    setShowLoggedIn(true);
                    audio.play();
                    window.navigator.vibrate(500);
                }
            });
            setDbUnsubscribe(() => unsub);
        }
    }

    const allowNotPhone = () => {
        setDisablePCCheck(true);
        startSession();
    }

    useEffect(() => {
        const intervalHandler = setInterval(() => {
            setExpireTimer(seconds => {
                console.log("RunRUn!")
                if (seconds == 0) {
                    startSession();
                }
                return seconds < 0 ? seconds : seconds - 1
            });
        }, 1000);
        if (isMobile && props.user !== null && ready) {
            startSession();
        }
        return () => clearInterval(intervalHandler);
    }, []);


    const max = (value, max) => {
        return value > max ? max : value
    }
    return (
        <div>
            {(isMobile || disablePCCheck) && props.user !== null ? (<div className="min-vh-100">
                {ready ? <div className="">
                    <p> a</p>
                    <Container className="mt-5">
                        <center>{sessionId === null ? <div style={{ height: `${max(width * 0.8, 500)}px` }}>
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <Spinner animation="border" className="" />
                            </div>
                        </div> : <div>
                            <QRcode className="code-rounded" level="H" value={sessionId} includeMargin={true} size={max(width * 0.8, 500)} />
                            <h2>{Math.floor(expireTimer / 60)}:{("0" + (expireTimer % 60)).slice(-2)}<i class="bi bi-arrow-clockwise" style={{ cursor: "pointer" }} onClick={() => startSession()}></i></h2>
                            <h3 className="mt-5">{props.user.displayId}としてログインします</h3>
                            <h3 className="mt-5"><Link to="/oac/smart">OAc Smartについて</Link></h3>
                        </div>}
                        </center>
                    </Container>
                </div> : <div className="min-vh-100 py-5">
                    <center>
                        <h3 className="mt-5">OAc Smartログインは現在準備中です！</h3>
                        <h4 className="mt-5"><Link to="/oac/smart">OAc Smartについて</Link></h4>
                        <center style={{ fontSize: "200px" }}><img src={Icon} width="200px" /></center>
                    </center>
                    </div>}
            </div>) : (<OAcSmart allowNotPhone={allowNotPhone} />)}
            <Modal show={showLoggedIn} onHide={() => setShowLoggedIn(false)} centered bg="dark">
                <Modal.Header className="bg-dark text-light">
                    <Modal.Title>Enjoy!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-light">
                    <Container>
                        {consoleName}へログインしました！
                    </Container>
                </Modal.Body>
                <Modal.Footer className="bg-dark text-light">
                    <Button variant="secondary" onClick={() => setShowLoggedIn(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OAc;